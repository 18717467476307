import { webClient } from "@api/web/WebClient";
import CollapseBox from "@components/web/CollapseBox";
import { withWebLayout } from "@components/web/Decorators";
import DownloadAppButton from "@components/web/DownloadAppButton";
import { FrontPageLayout } from "@components/web/layouts/FrontPageLayout";
import { useLocalization } from "@hooks/localization";
import { useQueryParameters } from "@hooks/navigation";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import i18n from "@utils/i18n";
import { navigateTo404 } from "@utils/navigation";
import { getJwtCookiePayload } from "@utils/swaggerClientUtils";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import bringLogo from "../images/julehjaelpen/bringlogo.svg";
import metaImage from "../images/julehjaelpen/julehjaelpendk_julemand.jpeg";
import bigImage from "../images/julehjaelpen/julehjaelpendk_julemand_1200x420.jpeg";
import packageIcon from "../images/julehjaelpen/julehjaelpendk_pakke.svg";
import LogoWhiteIcon from "../images/newFrontpage/logo/logo-white-icon.svg";
import styles from "./index.module.scss";

type QueryParameters = {
	token: string;
	userId: string;
	planId: string;
	couponCode: string;
	referrerUserId?: string
};

export default withWebLayout(function Julehjaelpen() {
	const params = useQueryParameters<QueryParameters>();
	const t = useLocalization();

	const [isShowingBotton, setIsShowingButton] = useState(false);
	const [isOpenLoginMenu, setIsOpenLoginMenu] = useState(false);
	const [isConfirmDialog, setIsConfirmDialog] = useState(false);
	const [isSuccessDialog, setIsSuccessDialog] = useState(false);
	const [isButtonDisabled, setIsButtonDisabled] = useState(false);
	const [isBringPackageLabel, setIsBringPackageLabel] = useState<string>();


	useEffect(() => {

		const UserLoggedIn = async () => {
			try {
				if (!getJwtCookiePayload() && !!params.token) {
					await webClient().webAuthenticationsSetJwtCookiesFromJwtPost({
						body: {
							apiJwt: params.token,
						}
					});
				}

				const languageResponse = await webClient().webUsersLanguageGet();
				const language = languageResponse.language;
				if (i18n.language !== language) {
					i18n.changeLanguage(language);
				}

			} catch (error) {
				navigateTo404();
			}
		};
		UserLoggedIn();
		setIsShowingButton(true);
	}, [params]);


	const onButtonClicked = () => {
		if (!getJwtCookiePayload()) {
			setIsOpenLoginMenu(true);
			return;
		}
		setIsConfirmDialog(true);
	}

	return <FrontPageLayout removeBlueBodyBackground>
		<Helmet
			title="Støt Julehjælpen.dk med Bring og Reshopper">
			<meta name="description" content="Vær med til at opfylde juleønsker for økonomisk trængte familier med brugte og nye gave-donationer til Julehjælpen.dk med Bring og Reshopper." />
			<meta name="robots" content="index, follow" />
			<meta property="og:image" content={metaImage} />
		</Helmet>
		<Container className={styles.julehjaelpContainer}>
			<Box className={styles.wishlistContainer}>
				<Box className={styles.logoContainer}>
					<Box>
						<img alt="Reshopper" src={LogoWhiteIcon} width={50} height={50} />
					</Box>
					<Box>
						<img alt="Bring" src={bringLogo} width={89} height={34} />
					</Box>
					<Box />
				</Box>
				<Box className={styles.imageContainer} >
					<img alt="Julehjælpen.dk - Alle børn fortjener en god jul" src={bigImage} width={1200} height={420} />
				</Box>
				<Box style={{ backgroundColor: "#D6F3FF", paddingTop: "50px", paddingBottom: "70px", paddingLeft: "25px", paddingRight: "25px" }}>
					<Box style={{ width: "100%", maxWidth: "675px", margin: "auto" }}>
						<Typography variant="h1" style={{ marginBottom: "2px" }}>Juleindsamling er lukket for i år!</Typography>
						<Typography variant="h2" style={{ marginBottom: "8px", fontSize: "25px" }}>Tusind tak for donationerne<span style={{whiteSpace: "nowrap"}}>❤️‍🩹🙏</span></Typography>
						<Typography>
							Med jeres hjælp har Bring og Reshopper nu fået indsamlet tusindvis af julegaver til Julehjælpen, som nu kan gøre en forskel for de mange udsatte børnefamilier i Danmark.<br /><br />
							Alle julegaverne er blevet sorteret og indsamlet hos Bring og er overleveret til Julehjælpen, som i december måned uddeler julegaverne til alle de familier, som har ansøgt om julehjælp i år.
						</Typography>
						<Typography style={{ marginTop: "20px", fontWeight: "bold" }}>
							TAK! Fordi alle børn fortjener en god jul!
						</Typography>
						<Typography style={{ marginTop: "10px", fontWeight: "bold" }}>
							Glædelig jul🎄<br />
							Bring, Reshopper og Julehjælpen
						</Typography>
						<Typography></Typography>
					</Box>
				</Box>
				<Box className={styles.wishlistText}>
					<Box className={styles.infoText}>
						<Typography variant="h2">Hjælp os med at donere brugte og nye gaver fra Julehjælpens ønskeliste</Typography>
						<Typography>
							Vær med til at opfylde juleønsker for økonomisk trængte familier ved at donere brugte og nye gaver. Se om du ligger inde med noget på ønskelisten herunder!👇
						</Typography>

						<Typography variant="h2">Sådan donerer du</Typography>
						<Typography>
							Ønskelisten for neden viser hvilke donationer Julehjælpen har behov for. Har du lyst til at donere, bestiller du en gratis pakkekode, pakker din donation ind, og indleverer i en Bring pakkeshop.<br />
							Vi sørger for, at pakkerne kommer frem til Julehjælpens familier og gør en forskel for dem, der har behov for det.<br /><br />
							Husk at sikre, at eventuelle brugte donationer er i rigtig pæn stand - en stand du gerne selv ville modtage i gave.
						</Typography>

						<Typography variant="h2">Ønskeliste</Typography>
					</Box>
					<JuleHjaelpWishlist />
				</Box>
			</Box>
			<Box className={styles.faqContainer}>
				<Box className={styles.iconPackage}>
					<img src={packageIcon} width={138} height={174} alt={t("Plus_Signup_Family_LowerShippingPrices_Title")} />
				</Box>
				<Box className={styles.faqText}>
					<Typography variant="h2">Tip til dig der vil give en donation</Typography>
					<Typography>Brug gerne den ønskeliste Julehjælpen.dk har lavet og send venligst kun nye og brugte ting, der er i en stand, som du selv vil modtage i gave 🙏</Typography>
				</Box>
				<JuleHjaelpFAQ />
			</Box>
		</Container>
	</FrontPageLayout>
});

const JuleHjaelpWishlist = () => {
	return <Box>
		<CollapseBox
			title={"1. Flyverdragter"}
			text={<span>
				Flyverdragter i rigtig pæn stand vil gøre lykke hos rigtig mange af de udsatte familier, som vi hjælper, da det er en rigtig dyr post på budgettet. Flyverdragter i alle farver og til alle aldre tages imod med kyshånd.
			</span>}
			style={{ borderBottom: "1px solid #D9E7F2" }} />
		<CollapseBox
			title={"2. Vinterjakker/bukser"}
			text={<span>
				Både vinterbukser og vinterjakker er en nødvendighed for familierne - men en dyr post, da børn som bekendt hurtigt vokser ud af dem. Vi tager hjertens gerne imod både vinterjakker og vinterbukser til børn i alle aldre - det behøver ikke være et sæt, og det behøver heller ikke være begge dele. Så længe de er i rigtig pæn stand.
			</span>}
			style={{ borderBottom: "1px solid #D9E7F2" }} />
		<CollapseBox
			title={"3. Baby- & børnetøj i god stand"}
			text={<span>
				Vi tager med glæde imod bluser, t-shirts, bukser, shorts, kjoler mv - så længe det er uden pletter, huller eller tydelig slitage. Det skal som alt andet gerne have en stand, du selv ville blive glad for at modtage i gave.
			</span>}
			style={{ borderBottom: "1px solid #D9E7F2" }} />
		<CollapseBox
			title={"4. Plus Plus"}
			text={<span>
				Plus Plus er et kæmpe hit blandt børn - også udsatte børn. Det er dog noget, der ofte ikke er råd til, hvorfor det for familierne kan føles som rendyrket luksus at kunne give sit barn noget fra Plus Plus.
			</span>}
			style={{ borderBottom: "1px solid #D9E7F2" }} />
		<CollapseBox
			title={"5. LEGO"}
			text={<span>
				LEGO er selvfølgelig et kæmpe hit blandt børn - også udsatte børn. Men det er også noget, der ofte ikke er råd til, hvorfor det for familierne kan føles som ren luksus at kunne give sit barn noget fra LEGO. Hvis dét, du har i tankerne, er et sæt (som meget LEGO Friends), er det vigtigt, at det er et komplet sæt, og at der derfor ikke er brikker, der mangler eller er gået i stykker. Hvis det oprindeligt var en kasse med blandet LEGO, er det selvfølgelig helt ok, hvis der mangler et par brikker.
			</span>}
			style={{ borderBottom: "1px solid #D9E7F2" }} />
		<CollapseBox
			title={"6. Brio"}
			text={<span>
				Brio er et kæmpe hit blandt børn - også udsatte børn. Det er dog noget, der ofte ikke er råd til, hvorfor det for familierne kan føles som rendyrket luksus at kunne give sit barn noget fra Brio. Det er vigtigt, at det, der sendes, er et komplet sæt, barnet kan lege med, og ikke eksempelvis en rund togbane, hvor et stykke mangler.
			</span>}
			style={{ borderBottom: "1px solid #D9E7F2" }} />
		<CollapseBox
			title={"7. Paw Patrol"}
			text={<span>
				Paw Patrol er et kæmpe hit blandt børn - også udsatte børn. Det er dog noget, der ofte ikke er råd til, hvorfor det for familierne kan føles som rendyrket luksus at kunne give sit barn noget fra Paw Patrol. Det er vigtigt, at det, der sendes, er et komplet sæt, barnet kan lege med, og ikke eksempelvis noget, hvor der mangler en vigtig brik eller figur.
			</span>}
			style={{ borderBottom: "1px solid #D9E7F2" }} />
		<CollapseBox
			title={"8. Gurli Gris"}
			text={<span>
				Gurli Gris er et kæmpe hit blandt børn - også udsatte børn. Så længe det er i god stand, tager vi imod alt med Gurli Gris med kyshånd.
			</span>}
			style={{ borderBottom: "1px solid #D9E7F2" }} />
		<CollapseBox
			title={"9. Minisjang produkter"}
			text={<span>
				Minisjang produkter er et kæmpe hit blandt børn - også udsatte børn. Produktet skal selvfølgelig være komplet (det er så ærgerligt at modtage et Cirkeline vendespil, hvor der mangler et par brikker) og i god stand.
			</span>}
			style={{ borderBottom: "1px solid #D9E7F2" }} />
		<CollapseBox
			title={"10. Playstation 4 spil "}
			text={<span>
				Alle Playstation 4 spil, der stadig virker, har interesse. Familierne har normalt ikke en Playstation 4 i deres eget hjem, men derfor kan det godt være en fantastisk oplevelse at kunne tage et spil med til en spil-aften i klassen eller med hen til nogle venner.
			</span>}
			style={{ borderBottom: "1px solid #D9E7F2" }} />
	</Box>
};

const JuleHjaelpFAQ = () => {

	return <Box className={styles.faqContent}>
		<Typography variant="h2">Spørgsmål og svar</Typography>
		<CollapseBox
			title={"Hvordan donerer jeg julegaver?"}
			text={<span>
				Ønskelisten her på siden viser, hvilke donationer Julehjælpen.dk har behov for.<br />
				Har du lyst til at donere, gør du følgende:<br />
				1. Bestil en gratis pakkekode,<br />
				2. Pak din donation ind og skriv pakkekode og “Julehjælpen” på pakken,<br />
				3. Indlever i en Bring pakkeshop.<br />
				Vi sørger for, at pakkerne kommer frem til Julehjælpens familier og gør en forskel for dem, der har behov for det.
			</span>} />
		<CollapseBox
			title={"Hvorfor er Reshopper-profil påkrævet for at donere?"}
			text={<span>
				Det er, fordi Bring er en integreret del af Reshopper, hvor vi har gjort det nemt at sende pakker, så du kan nøjes med at skrive pakkekoden på pakken. Derved undgår du at printe pakkelabels eller skrive adresseoplysninger. Det hele er bygget ind i vores teknologiske platform, så det er både nemt, tidsbesparende og sikkert for dig at sende din donation via Reshopper. <br />
				Har du ikke en profil, så kan du downloade appen og oprette en profil. <DownloadAppButton showAsLink />.
			</span>} />
		<CollapseBox
			title={"Hvordan sender jeg en donation?"}
			text={<span>
				Pak gaven på en sikker måde og skriv din pakkekode på emballagen tydeligt. Mærk desuden din pakke med “Julehjælpen”.
			</span>} />
		<CollapseBox
			title={"Hvor indleverer jeg min donation?"}
			text={<span>
				Din gave skal indleveres til en Bring pakkeshop. Du kan bruge <Link href="https://www.bring.dk/kort" target="_blank" title="www.bring.dk/kort">bring.dk</Link> til at finde nærmeste pakkeshop i dit postnummer.<br />
				Tip: Zoom ud på kortet, og tryk på “søg i dette areal” for at få vist endnu flere pakkeshops.
			</span>} />
		<CollapseBox
			title={"Hvor stor må pakken være?"}
			text={<span>
				Længde: max. 150 cm <br />
				Længde + omkreds: max. 300 cm <br />
				Vægt: max. 20 kg
			</span>} />
		<CollapseBox
			title={"Hvordan udvælger I familierne?"}
			text={<span>
				Alle familier, der søger julehjælp hos Julehjælpen.dk, indsender en ansøgning med diverse faktuelle oplysninger samt en beskrivelse af situationen i familien. Herefter skal familien MitID-verificere sig. Julehjælpen.dk modtager ca. 10.000 ansøgninger hvert år, og hver eneste af disse ansøgninger behandles manuelt af deres frivillige. Målet er at udvælge de familier, der har allermest brug for hjælp til at kunne give deres børn en GOD jul.
			</span>} />
		<CollapseBox
			title={"Hvordan søger jeg julehjælp? "}
			text={<span>
				Du kan søge julehjælp ved at besøge <Link href="https://www.julehjaelpen.dk/sog-julehjaelp-hos-julehjaelpendk" target="_blank" title="www.julehjaelpen.dk/sog-julehjaelp-hos-julehjaelpendk">Julehjælpen.dk</Link> og udfylde en ansøgning.<br />
				Julehjælpen.dk behandler ansøgninger løbende og lukker senest for ansøgninger d. 1. december.<br />
				Hvis du godkendes til julehjælp gennem Julehjælpen.dk, får du adgang til at søge donationer i deres Gaveportal.
			</span>} />
		<CollapseBox
			title={"Hvem kan søge julehjælp?"}
			text={<span>
				Julehjælpen.dk yder julehjælp til udsatte børnefamilier i Danmark, der har børn under 18 år.
			</span>} />
		<CollapseBox
			title={"Sådan pakker du din donation"}
			text={<span>
				Pak gaven på en sikker måde og skriv din pakkekode tydeligt på emballagen. Skrøbelige genstande skal indpakkes med støddæmpende materiale for at beskytte mod tryk og stød under transporten.
			</span>} />
		<CollapseBox
			title={"Hvem er Julehjælpen.dk?"}
			text={<span>
				Julehjælpen.dk er en 100% frivillig non-profit forening, der har til formål at hjælpe udsatte børnefamilier i Danmark med at give deres børn en GOD jul. Ca. 100 frivillige sikrer hvert år, at tusindvis af udsatte børnefamilier får hjælp til f.eks. julemad, gaver, tøj, sko mv.<br />
				Alle i Julehjælpen.dk er frivillige - fra ny frivillig og hele vejen til bestyrelsesformanden - hvorfor de også er stolte af at kunne sige, at 0% af bidragene, de modtager, går til lønninger. Samtidig er Julehjælpen.dk en online forening - og fra 2023 er det endelig lykkedes at få doneret lidt lagerplads rundt om i landet, hvorfor det fra i år også officielt kan siges, at 0% af bidragene går til husleje.
			</span>} />
	</Box>
};
