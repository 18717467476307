
export function formatSeoUrlSegment(urlSegment: string|null|undefined) {
    if(!urlSegment)
        return "";

    urlSegment = urlSegment.replace(/[^\wæøå]/g, '-');

    while(urlSegment.indexOf('--') > -1)
        urlSegment = urlSegment.replace('--', '-');

    while(urlSegment.startsWith("-"))
        urlSegment = urlSegment.substr(1);

    while(urlSegment.endsWith("-"))
        urlSegment = urlSegment.substr(0, urlSegment.length - 1);

    return encodeURIComponent(urlSegment.toLowerCase());
}

export const navigateToExternalUrl = (url: string, shouldOpenNewTab: boolean = true) => {
    if (typeof window === "undefined")
        return;

    shouldOpenNewTab ?
        window.open(url, "_blank") :
        window.location.href = url;
}

export function navigateToApp(): null {
    if (typeof window === 'undefined')
        return null;

    window.location.href = "reshopper://reset";
    return null;
}

export function navigateTo404(fallbackUrl?: string): null {
    if (typeof window === 'undefined')
        return null;

    console.error("A 404 was triggered.");

    window.location.href = "/404/?fallback=" + encodeURIComponent(fallbackUrl || "");
    return null;
}